<template>
  <div
    class="page beijingAncientTreeResources_page_pc"
    :style="{ backgroundImage: `url(${pageBgImg})` }"
  >
    <div class="flex-1">
      <div class="container p-h-12">
        <div :class="['detailContainer']">
          <p>
            北京市的古树资源丰富且独特。它们不仅为这座城市增添了无尽的生机与绿意，更是城市历史与文化的重要载体，见证着北京的变迁与发展，传递着古都的韵味，凝聚着岁月的痕迹和人文的情怀。
          </p>
          <p>
            据北京市古树名木资源调查数据显示，全市共有古树名木41865株，其中一级古树6198株，占总数的15%；二级古树34329株，占总数的82%；名木1338株，占总数的3%。
          </p>
          <p>
            北京市古树资源分布在公园管理中心的较多，共有13973株，占总数的33.4%;
            海淀区共有6820株，占比为16.3%；昌平区共有5978株，占比为14.4%。
          </p>
          <p>
            北京的古树名木树种丰富多样，共有33科、56属、74种。主要树种为侧柏22570株、油松6990株、桧柏5753株和国槐3531株，共计38844株，占总数的92.8%，其他树种3021株，占总数的7.2%。
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-ct footer p-b-20">
      ·奥迪中国与北京绿化基金会联合呈现·<br />支持单位：
      国家林业草原古树健康与古树文化工程技术研究中心
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {
      pageBgImg: require("@/images/pc/北京古树游览推荐-PC三级页面｜内容详情.jpg")
    };
  },
  methods: {
    gotoPage(routePath) {
      if (routePath) {
        this.$router.push(routePath);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.beijingAncientTreeResources_page_pc {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;

  .container {
    margin: 252px auto 0;
    width: 80%;
    max-width: 900px;
    border-radius: 12px;
    background: rgba($color: #000000, $alpha: 0.4);
    border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .detailContainer {
    flex-grow: 0;
    overflow-y: auto;
    padding: 20px;
    text-align: left;
    text-indent: 2em;
    font-size: 24px;
    line-height: 40px;
  }
  .footer {
    font-size: 20px;
  }
}
</style>
